<template>
<div id="channel_price_upd_log">
    <!-- 国际客户详情-弹出层 -->
    <el-dialog class="cur_dialog" title="" center :visible.sync="dialog_visible" fullscreen destroy-on-close :show-close="false" :modal-append-to-body="false">
        <!-- 头 -->
        <el-row class="dialog_top">
            <span>国际客户详情</span>
            <i class="el-icon-close dialog_top_close_button" @click="back()" style="font-size:24px"></i>
        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">

            <el-row style="overflow: auto;padding-right:10px;" :style="{height: (content_heigth+'px')}">
               
                <el-row class="dialog_top cursor" @click.native="show_base = !show_base" style="text-align: left;padding-left:15px;">
                    <span>基本信息</span>
                    <i class="dialog_top_close_button" :class="show_base ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"
                        style="font-size:24px"></i>
                </el-row>
                <!-- 基本信息 -->
                <el-table v-if="show_base" :data="tableData_base" :show-header="false"  style="width: 100%" size="mini" >
                    <el-table-column  fixed  prop="label"  width="110"  align="center"> </el-table-column>
                    <el-table-column  prop="show_value"  min-width="200" align="right"> </el-table-column>
                </el-table>

                                    
            </el-row>
            
        </el-row>

    </el-dialog>

</div>
</template>
<script>
//import { defineComponent } from '@vue/composition-api'
import API from '../../../api/api';
// import {dateFtt} from '../../../api/tool';

//import axios from "axios";
export default {
     components: {
    },
    props:{
        prop_change_i:0,
        prop_cpid:""
    },
    watch:{//监听props
        prop_change_i(val){
            //数据清除
            this.dataClear();
            if(val == 0){
                this.dialog_visible = false;//弹出框是否可见 
            }else{
                this.dialog_visible = true;//弹出框是否可见 

                setTimeout(() => {
                    //获取数据
                    this.getData();
                }, 1);
            }
        }
    },

    
    data(){
        return{
            dialog_visible: false,//弹出框是否可见  false:不可见

            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度

            show_base:true,//客户基本信息 是否展示  true:展示
            tableData_base:[],//客户基本信息数据
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
        //初始化设置
        this.initSetUp();

    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);
        },
         //数据清除
        dataClear(){
            this.show_base = this.$options.data().show_base;//单个恢复初始化
            this.tableData_base = this.$options.data().tableData_base;//单个恢复初始化
        },
        //跳转到客户
        to_user(){
            API.router_to("/inter_user_detail/"+this.prop_cpid);
        },

        //查询
        getData(){
            this.tableData_base = this.$options.data().tableData_base;//单个恢复初始化

            var cpid = this.prop_cpid;//客户id
           //请求接口
            API.Int_UserServlet({
                param: "detail",
                cpid:cpid,
            }).then((res) => {
                //console.log(res);
                if (res.recode === 0) {

                    this.detail_obj = res.detail;
                    this.tableData_channel_group =  res.detail.user_channel_groups;////通道选取方式为指定通道组时，指定的通道列表
                    var show_value = "";


                    var table_obj = {label:"客户账号",show_value:res.detail.cpid,value:res.detail.cpid,name:"cpid"};
                    this.tableData_base.push(table_obj);

                    if( 1 == 1){
                        table_obj = {label:"名称",show_value:res.detail.cpname,value:res.detail.cpname,name:"cpname"};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"接口密码",show_value:res.detail.cppwd,value:res.detail.cppwd,name:"cppwd"};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"客户鉴权IP",show_value:res.detail.ip,value:res.detail.ip,name:"ip"};
                        this.tableData_base.push(table_obj);


                        if(res.detail.states == 0){
                            show_value = "可用";
                        }else if(res.detail.states == 1){
                            show_value = "注销";
                        }else if(res.detail.states == 2){
                            show_value = "已删除";
                        }else if(res.detail.states == 3){
                            show_value = "暂停";
                        }else{
                            show_value = "未知";
                        }
                        table_obj = {label:"客户状态",show_value:show_value,value:res.detail.states,name:"states"};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"客户接入方式",show_value:res.detail.interface_type == 0 ? 'CMPP' : 'HTTP',value:res.detail.interface_type,name:"interface_type"};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"回调地址",show_value:res.detail.url,value:res.detail.url,name:"url"};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"备注",show_value:res.detail.remark,value:res.detail.remark,name:"remark"};
                        this.tableData_base.push(table_obj);
                    }
                }
            });
        },

        //关闭
        back(){
            this.dialog_visible = false;//弹出框是否可见   false:不可见
        },


    }
};

</script>

<style scoped>
/* 增加或编辑弹框中内容距离标题的距离 */
.cur_dialog >>>.el-dialog__body {
    padding: 0px 0px;
}
.cur_dialog >>>.el-dialog__header {
    padding: 0px 0px;
}

</style>