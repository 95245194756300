<template>
    <!-- 国际菜单》下行查询 -->
    <div id="app_inter_mt_list">
        <!-- 头 -->
        <el-row class="head_top">
            <div class="head_top_back cursor"><i class="el-icon-arrow-left" @click="goBack()"></i></div>

            <div class="head_top_title">国际下行查询</div>

        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">

             <!-- 查询条件 -->
            <el-row id="query_condition_id"  class="cursor" style="margin:10px 0px;">
                <el-form  :model="form_data" :inline="true" label-width="80px" style="margin-bottom: 12px;">
                    <el-form-item label="国际客户" >
                        <el-select v-model="form_data.cpid" filterable clearable placeholder="默认全部" size="mini" style="width:240px">
                            <el-option
                                v-for="item in userDataList"
                                :key="item.cpid"
                                :label="item.cpid+'('+item.cpname+')'"
                                :value="item.cpid">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="手机号" label-width="60px">
                        <el-input v-model="form_data.phone" clearable placeholder="默认全部" size="mini" style="width:140px;"></el-input>
                    </el-form-item>

                    <el-form-item label="日期" label-width="40px">
                        <el-date-picker
                            v-model="form_data.yyyyMM"
                            type="month"
                            placeholder="选择日期时间" size="mini" style="width:120px;">
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" @click="submit_cha_xun()" size="mini">查询</el-button>
                    </el-form-item>
                </el-form>
            </el-row>
            
            <el-row style="overflow: auto;" :style="{height: ((content_heigth-pagination_height-query_condition_height)+'px')}">
                <template v-for="item in tableData">

                    <el-descriptions  title="" :column="1" size="mini" border>

                        <el-descriptions-item label="cpid" label-class-name="el_descriptions_label_class">
                            {{item.cpid}}
                            <el-button type="text"  @click="detail(item.cpid)" style="padding:0px;">详情</el-button>
                        </el-descriptions-item>

                        <el-descriptions-item label="主键" label-class-name="el_descriptions_label_class">
                            {{item.smsid}}
                        </el-descriptions-item>

                        <el-descriptions-item label="手机号" label-class-name="el_descriptions_label_class">
                            {{item.phone}}
                        </el-descriptions-item>

                        <el-descriptions-item label="内容" label-class-name="el_descriptions_label_class">
                            {{item.content}}
                        </el-descriptions-item>

                        <el-descriptions-item label="受理时间" label-class-name="el_descriptions_label_class">
                            {{item.accept_time}}
                        </el-descriptions-item>

                        <el-descriptions-item label="提交时间" label-class-name="el_descriptions_label_class">
                            {{item.submit_time}}
                        </el-descriptions-item>

                        <el-descriptions-item label="发送运营商时间" label-class-name="el_descriptions_label_class">
                            {{item.network_time}}
                        </el-descriptions-item>

                        <el-descriptions-item label="运营商状态时间" label-class-name="el_descriptions_label_class">
                            {{item.state_time}}
                        </el-descriptions-item>

                        <el-descriptions-item label="计费条数" label-class-name="el_descriptions_label_class">
                            {{item.snum}}
                        </el-descriptions-item>

                        <el-descriptions-item label="状态" label-class-name="el_descriptions_label_class">
                            {{item.state}}
                        </el-descriptions-item>

                    </el-descriptions>

                    <el-divider style="background-color: #EE0909;margin: 5px 0;"></el-divider>
                    
                </template>

                <template v-if="tableData.length == 0">
                    <el-row type="flex" justify="center">无数据</el-row>
                </template>
                
            
            </el-row>

            <el-row id="pagination_id" style="padding:10px 0 0 0;text-align:center;line-height:26px;">
                <!-- 分页  layout="total, sizes, prev, pager, next, jumper"   small-->
                <el-pagination background  
                    layout="total, prev, pager, next,jumper"
                    :total="tableData_total"
                    small
                    :pager-count="5"
                    @size-change="pageSizeChange"
                    @current-change="pageCurrentChange"
                    :page-sizes="[5,10, 20, 30, 40,100]"
                     :page-size="pageSize"
                     style="white-space: normal !important;">
                </el-pagination>
            </el-row>
        </el-row>

         <!-- 国际客户详情组件 -->
        <UserDetailSelect :prop_change_i="prop_user_detail_i"
            :prop_cpid="prop_user_detail_cpid"
        ></UserDetailSelect>
    </div>
</template>
<script>
import API from '../../../api/api';
import {dateFtt} from '../../../api/tool.js';
import UserDetailSelect from '../inter_user/inter_user_detail_select.vue';//组件 客户详情-弹出层

//import axios from "axios";
export default {
    components: {
        UserDetailSelect
    },
    
    data(){
        return{
            content_heigth:500,//内容的高度
            content_width:500,//内容的宽度

            query_condition_height:40,//查询条件的高度

            form_data:{
                cpid:"",//cpid
                phone:"",//手机号
                yyyyMM:new Date(new Date().getTime()-5*60*1000)//日期
            },


            indexPage:1,//分页 第几页
            pageSize:10,//分页 一页的数量值
            
            tableData:[],//表格数据
            tableData_total:0,//表格数据总量
            pagination_height:40,//分页的高度

            userDataList:[],//客户列表数据-请求接口获取


            //国际客户详情
            prop_user_detail_i:0,//客户详情  组件 弹出框是否可见
            prop_user_detail_cpid:"",//客户详情  组件  的cpid

        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
        //初始化设置
        this.initSetUp();
    },
    //activated和deactivated配合keep-alive标签使用!
    activated() {
        // this.indexPage = this.$options.data().indexPage;//单个恢复初始化
        //重置所有数据
	    Object.assign(this.$data, this.$options.data.call(this));
	    //初始化设置
        this.initSetUp();

        //获取客户列表数据-请求接口获取
        this.getUserDataList();
        
        //查询
        this.submit_cha_xun();


    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 100);

            this.$nextTick(function(){
                //查询条件的高度
                var query_condition = document.getElementById('query_condition_id');
                if(!API.isEmtry(query_condition)){
                    this.query_condition_height = query_condition.clientHeight;
                }
            });
        },

        //查询
        submit_cha_xun(){

            var submit_obj = {};
            submit_obj.param = "mt_list";
            submit_obj.indexPage = this.indexPage;
            submit_obj.pageSize = this.pageSize;

            submit_obj.cpid = this.form_data.cpid;
            submit_obj.phone = this.form_data.phone;

            submit_obj.yyyyMM = dateFtt(this.form_data.yyyyMM,"yyyyMM");
            

            //请求接口
            API.Int_MtServlet(
                submit_obj
            ).then((res) => {
                //console.log(res);
                if (res.recode === 0) {
                    this.tableData = res.list;
                    this.tableData_total = res.totalNum;

                    this.$nextTick(function(){
                        //获取分页的高度
                        var pagination = document.getElementById('pagination_id');
                        if(!API.isEmtry(pagination)){
                            this.pagination_height = pagination.clientHeight;
                        }
                    });
                } 
            });
        },
        //分页大小发生改变调用
        pageSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.pageSize = val;
            //查询-- 请求接口
            this.submit_cha_xun();
        },
        //分页 当前页 发生改变调用
        pageCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.indexPage = val;
            //查询-- 请求接口
            this.submit_cha_xun();
        },

        //获取客户列表数据-请求接口获取
        getUserDataList(searchID){
            //请求接口
            API.Int_UserServlet({
                param: "listAISelect",
                searchID:searchID,//输入框填写的查询条件
            }).then((res) => {
                if (res.recode === 0) {
                    this.userDataList = res.list;//客户列表数据--请求接口获取
                }
            });
        },
        
        //客户详情
        detail(cpid){
            console.log('客户详情 跳转到客户详情页面cpid:'+cpid);
            this.prop_user_detail_cpid = cpid;//客户详情  组件  的cpid
            this.prop_user_detail_i ++ ;//客户详情  组件 弹出框是否可见
            
        },

        //返回
        goBack() {
            API.router_to("/inter_index");
        }
    }
};

</script>

<style scoped>

#app_inter_mt_list>>>.el_descriptions_label_class{
    text-align: center;
    width: 80px;
    
}

/*form表单样式调整*/
#app_inter_mt_list>>>.el-divider {
  background-color: #409EFF;
  margin: 5px 0;
} 

</style>